import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit} from "@angular/core";
import {AbstractSocialButton} from "./abstract-social-button";
import {ApiCommunicationService} from "../../../shared/model/services/api-communication/api-communication.service";
import {UserSessionService} from "../../../auth/session/services/user-session/user-session.service";
import {Like} from "../../../shared/model/data/social/Like";
import {User} from "../../../shared/model/data/user/User";
import {likePopAnimation} from "../../animations/socialIconsStateChangeAnimation";
import {ModalServiceProxy} from "../../../commons/services/modal/modal.service.proxy";
import {Content} from "../../../shared/model/content/Content";
import {LikeType} from "../../../shared/model/data/enums/LikeType";
import {Product} from "../../../shared/model/data/shop/product/Product";
import {ContentType} from "../../../shared/model/data/enums/ContentType";
import {LikeService} from "../../services/like/like.service";

@Component({
	selector: "app-social-button-like",
	templateUrl: "./abstract-social-button.html",
	styleUrls: ["./abstract-social-button.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		likePopAnimation
	]
})
export class SocialButtonLikeComponent extends AbstractSocialButton implements OnInit {

	@Input()
	public item: Content | Product;

	@Input()
	public type: "product" | "post" | "microblogpost" | "event";

	// user's like
	private userLike: Like;

	constructor(@Inject(ApiCommunicationService) private api: ApiCommunicationService,
				@Inject(UserSessionService) private userSessionService: UserSessionService,
				@Inject(ModalServiceProxy) private modalServiceProxy: ModalServiceProxy,
				@Inject(ChangeDetectorRef) private changeDetector: ChangeDetectorRef,
				@Inject(LikeService) private likeService: LikeService) {
		super("like", "icon fi fi-ss-heart");
	}

	ngOnInit() {

		// init number to display
		if (this.item) {
			this.reFetchLikes();
		}

		// initialize like state
		this.userSessionService.userData.subscribe((user) => {
			this.initLikeState(user);
		});

	}



	/**
	 * Action executed when user clicks on like button.
	 */
	public clickAction(): void {

		// null check
		if (!this.item || !this.item._id) {
			throw new Error("Cold not like item. Item or its id is not present.");
		}

		// check if user is present
		if (!this.userSessionService.userDataValue || !this.userSessionService.userDataValue._id) {
			this.noActiveUserAction();
			return;
		}

		// toggle like state
		this.toggleLikeAction();
	}

	private noActiveUserAction(): void {
		this.modalServiceProxy.get().showLoginModal();
	}

	private toggleLikeAction(): void {

		// if the item is already liked, do the dislike and vice-versa
		if (this._iconState) {

			// only if the user's like is known
			if (this.userLike) {
				this.api.like().unlike(this.userLike._id).subscribe((response) => {

					// remove like state
					this.userLike = undefined;
					this._iconState = false;

					// decrease displayed number of likes
					this._displayedNumber--;

					this.likeService.add(this.item._id);

					// fire change detection
					this.changeDetector.markForCheck();
				});
			} else {
				// we assume that the like is removed
				this._iconState = false;
			}

		} else {

			const like: Like = new Like();
			like.content = this.item._id;

			if (this.type === "product") {
				// like item
				like.type = LikeType.PRODUCT;
				this.api.like().like(like).subscribe((resp: Like) => {
					this.handleLikeResponse(resp);
				}, (err) => {
					console.error("Could not like product.", err);
				});
			} else if (this.type === "post") {
				// like item
				like.type = LikeType.POST;
				this.api.like().like(like).subscribe((resp: Like) => {
					this.handleLikeResponse(resp);
				}, (err) => {
					console.error("Could not like post.", err);
				});
			} else if (this.type === "microblogpost") {
				// like item
				like.type = LikeType.MICROBLOGPOST;
				this.api.like().like(like).subscribe((resp: Like) => {
					this.handleLikeResponse(resp);
				}, (err) => {
					console.error("Could not like microblogpost.", err);
				});
			} else if (this.type === "event") {
				// like item
				like.type = LikeType.EVENT;
				this.api.like().like(like).subscribe((resp: Like) => {
					this.handleLikeResponse(resp);
				}, (err) => {
					console.error("Could not like event.", err);
				});
			}

		}

	}

	private handleLikeResponse(like: Like) {
		// the like object is store
		this.userLike = like;
		this.likeService.add(this.item._id);

		// the item is now liked
		this._iconState = true;

		// increase displayed number
		this._displayedNumber++;

		// fire change detection
		this.changeDetector.markForCheck();
	}

	/**
	 * Initializes like state variables.
	 * @param {User} user Optional user object to pass.
	 */
	private initLikeState(user?: User): void {

		// assign user object if not passed
		if (!user) {
			user = this.userSessionService.userDataValue;
		}

		// check if the current user has liked the item
		if (user && this.item && this.item.likes) {

			// get the user's like (if any)
			// @ts-ignore
			this.userLike = this.item.likes.find((like) => like.user._id === user._id);

			// icon state is based on user's like
			this._iconState = !!this.userLike;

		} else {
			this.userLike = undefined;
			this._iconState = false;
		}

		this.changeDetector.detectChanges();

	}

	private reFetchLikes() {
		if (!this.likeService.exist(this.item._id)) {
			this._displayedNumber = this.item.numberOfLikes;
			return;
		}

		const likeRequest = new Like();
		likeRequest.content = this.item._id;

		if ((<Product>this.item).brandName) {
			likeRequest.type = LikeType.PRODUCT;
		}

		switch ((<Content>this.item).type) {
			case ContentType.POST:
				likeRequest.type = LikeType.POST;
				break;
			case ContentType.MICROBLOGPOST:
				likeRequest.type = LikeType.MICROBLOGPOST;
				break;
			case ContentType.EVENT:
				likeRequest.type = LikeType.EVENT;
				break;
		}

		this.api.like().getLike(likeRequest).subscribe((resp: Array<Like>) => {
			this.item.likes = resp;
			this._displayedNumber = resp.length;

			this.initLikeState();

			this.likeService.remove(this.item._id);
			this.changeDetector.detectChanges();
		});
	}

}

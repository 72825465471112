import {ChangeDetectorRef, Component, Inject, Input, OnInit} from "@angular/core";
import {AbstractSocialButton} from "./abstract-social-button";
import {nullAnimation} from "../../animations/socialIconsStateChangeAnimation";
import {CollectionService} from "../../services/collection/collection.service";
import {Product} from "../../../shared/model/data/shop/product/Product";
import {SweetAlertService} from "../../../commons/services/sweetalert/sweet-alert.service";
import {UserSessionService} from "../../../auth/session/services/user-session/user-session.service";
import {ModalServiceProxy} from "../../../commons/services/modal/modal.service.proxy";

@Component({
	selector: "app-social-button-add-to-collection",
	templateUrl: "./social-button-add-to-collection.component.html",
	styleUrls: ["./abstract-social-button.scss"],
	animations: [nullAnimation]
})
export class SocialButtonAddToCollectionComponent extends AbstractSocialButton implements OnInit {

	@Input()
	public item: Product;

	@Input()
	public type: "product" | "post" | "microblogpost" | "event";

	constructor(public collectionService: CollectionService,
				@Inject(UserSessionService) private userSessionService: UserSessionService,
				@Inject(ModalServiceProxy) private modalServiceProxy: ModalServiceProxy,
				private changeDetector: ChangeDetectorRef,
				private alertService: SweetAlertService) {
		super("add-to-collection", "icon fi fi-sr-user-add");
	}

	ngOnInit() {
		this._displayedNumber = this.getDisplayedNumber();
	}

	clickAction(): void {
		// check if user is present
		if (!this.userSessionService.userDataValue || !this.userSessionService.userDataValue._id) {
			this.noActiveUserAction();
			return;
		}
	}

	public addToCollection(collectionId: string): void {
		this.collectionService.addToCollection(collectionId, <Product>this.item).subscribe((data) => {
			this._displayedNumber++;
			this.changeDetector.detectChanges();
		});
	}

	private noActiveUserAction(): void {
		this.modalServiceProxy.get().showLoginModal();
	}

	public addToNewCollection(): void {
			this.alertService.prompt({title: "Please enter the name of the collection"})
				.then((response: any) => {
					if (response && response.value) {
						this.collectionService.addToNewCollection(response.value, <Product> this.item)
							.then(() => {
								this._displayedNumber++;
							}).catch(() => {
							this.alertService.error({title: "Could not add this product to collection"});
						});
					}
				}).catch((err) => {
			});
	}

	private getDisplayedNumber() {
		return this.item && this.item.numberOfCollections
			? this.item.numberOfCollections : 0;
	}

}

import {Injectable} from "@angular/core";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ReportModalComponent} from "../../modals/report-modal/report-modal.component";
import {Product} from "../../../shared/model/data/shop/product/Product";
import {Content} from "../../../shared/model/content/Content";

@Injectable()
export class ReportService {

	constructor(private modalService: NgbModal) {
	}

	public openReportModal(toReport: Product | Content) {

		// open the modal
		const modal = this.modalService.open(ReportModalComponent, );
		// pass modal reference
		(<ReportModalComponent>modal.componentInstance).modalRef = modal;
		// pass the edit method reference
		// (<ReportModalComponent>modal.componentInstance).postServiceRef = this;
		(<ReportModalComponent>modal.componentInstance).toReport = toReport;
	}

}

import {animate, keyframes, query, state, style, transition, trigger} from "@angular/animations";

export const nullAnimation =
	trigger("iconAnimation", [
		state("active", style({})),
		state("inactive", style({}))
	]);

export const likePopAnimation =
	trigger("iconAnimation", [
		transition("inactive => active", [
			query(".icon", [
				animate("300ms", keyframes([
					style({transform: "scale(1)", offset: 0}),
					style({transform: "scale(.6)", offset: 0.3}),
					style({transform: "scale(1.3)", offset: 0.6}),
					style({transform: "scale(.9)", offset: 0.8}),
					style({transform: "scale(1)", offset: 1}),
				])),
			])
		]),
		transition("active => inactive", [
			query(".icon", [
				animate("300ms", keyframes([
					style({color: "#e67d9b", offset: 0}),
					style({color: "#DDDDDD", offset: 1}),
				])),
			])
		]),
	]);

<div class="social-buttons {{orientation}}">

	<!--like button-->
	<app-social-button-like
		ngbTooltip="{{ 'social.button.like' | translate }}" placement="top-left"
		*ngIf="showLikeButton"
		[item]="item" [type]="type"></app-social-button-like>

	<!--add to collection button-->
	<app-social-button-add-to-collection
		ngbTooltip="{{ 'social.button.collection' | translate }}" placement="top-left"
		*ngIf="showAddToCollectionButton"
		[item]="item" [type]="type"></app-social-button-add-to-collection>

	<!--comment button-->
	<app-social-button-comment
		ngbTooltip="{{ 'social.button.comment' | translate }}" placement="top-left"
		*ngIf="showCommentButton"
		[item]="item" [type]="type"></app-social-button-comment>

	<!--share button-->
	<app-social-button-share
		ngbTooltip="Share this {{(type === 'microblogpost' ? 'blog post' : type).toLowerCase()}}" placement="top-left"
		*ngIf="showShareButton"
		[item]="item" [type]="type"></app-social-button-share>
</div>

import {Pipe, PipeTransform} from "@angular/core";

@Pipe({ name: "dateOrdinal" })

export class DateOrdinalPipe implements PipeTransform {
	constructor() {
	}

	transform(content: string | Date) {
		return format(content instanceof Date ? content : new Date(content));
	}
}

function format(date: Date) {
	const day = date.getDate();
	const month = date.toLocaleString("default", { month: "long" });
	const year = date.getFullYear();

	return `${day}${getOrdinal(day)} ${month} ${year}`
}

function getOrdinal(number: number) {
	if (number > 3 && number < 21) return "th";
	switch (number % 10) {
		case 1:
			return "st";
		case 2:
			return "nd";
		case 3:
			return "rd";
		default:
			return "th";
	}
}

import {Component, OnInit} from "@angular/core";
import {ApiCommunicationService} from "../../../shared/model/services/api-communication/api-communication.service";
import {Content} from "../../../shared/model/content/Content";
import {Product} from "../../../shared/model/data/shop/product/Product";
import {NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {ReportRequest} from "../../../shared/model/request/report/ReportRequest";
import {ContentType} from "../../../shared/model/data/enums/ContentType";
import {SweetAlertService} from "../../../commons/services/sweetalert/sweet-alert.service";

@Component({
	selector: "app-report-modal",
	templateUrl: "./report-modal.component.html",
	styleUrls: ["./report-modal.component.scss"]
})
export class ReportModalComponent implements OnInit {

	public toReport: Content | Product;

	public modalRef: NgbModalRef;

	public requestBody = new ReportRequest();

	public isLoading = false;

	constructor(private api: ApiCommunicationService,
				private alertService: SweetAlertService) {
	}

	ngOnInit() {
		this.requestBody.contentId = this.toReport._id;
	}

	public reportAction() {
		this.isLoading = true;
		let request;
		if (this.toReport.hasOwnProperty("brandName")) {
			// product
			request = this.api.product().report(this.requestBody);
		} else {
			// content
			switch ((<Content>this.toReport).type) {
				case ContentType.EVENT:
					request = this.api.event().report(this.requestBody);
					break;
				case ContentType.POST:
					request = this.api.post().report(this.requestBody);
					break;
				case ContentType.MICROBLOGPOST:
					request = this.api.microblogpost().report(this.requestBody);
					break;
			}
		}

		request.subscribe(() => {
			this.isLoading = false;
			this.alertService.success({title: "Your report has been noted. Thank you for your feedback."});
			this.modalRef.close();
		}, () => {
			// error handling
			this.alertService.error({title: "Could not submit your report."});
			this.isLoading = false;
		});
	}

}

import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {EscapeHtmlPipe} from "./pipes/keep-html.pipe";
import {DateOrdinalPipe} from "./pipes/date-ordinal.pipe";

@NgModule({
	imports: [
		CommonModule,
	],
	declarations: [
		EscapeHtmlPipe,
		DateOrdinalPipe,
	],
	exports: [
		EscapeHtmlPipe,
		DateOrdinalPipe,
	]
})
export class CommonsPipesModule {
}

<div ngbDropdown #shareDrop="ngbDropdown">

	<a class="social-button {{'social-button-' + buttonClass}}"
	   [ngClass]="{'active' : iconState}"
	   ngbDropdownToggle
	   [@iconAnimation]="iconState ? 'active' : 'inactive'">
		<span class="number" [ngClass]="{'hide': type === 'shop'}">{{displayedNumber}}</span>
		<i class="lovegivr icon" [ngClass]="iconClass"></i>
	</a>

	<!--dropdown menu-->
	<div ngbDropdownMenu>
		<!--menu header-->
<!--		<h6 class="dropdown-header">{{ 'social.share.share-menu-title' | translate }}</h6>-->
		<!--my collections-->
<!--		<button class="dropdown-item" (click)="facebookSharingAction()"><i class="fab fa-facebook mr-2"></i> {{ 'social.share.share-on' | translate }} Facebook</button>-->
<!--		<a class="dropdown-item" [href]="'fb-messenger://share/?link='+ sharableUrl" target="_blank"><i class="fab fa-facebook-messenger mr-2"></i> {{ 'social.share.share-on' | translate }} Messenger</a>-->
		<a class="dropdown-item" [href]="'https://twitter.com/intent/tweet?url='+ sharableUrl" target="_blank"><i class="fab fa-twitter mr-2"></i> {{ 'social.share.share-on' | translate }} Twitter</a>
		<a class="dropdown-item" [href]="'https://www.reddit.com/submit?url='+ sharableUrl" target="_blank"><i class="fab fa-reddit mr-2"></i> {{ 'social.share.share-on' | translate }} Reddit</a>
<!--		<a class="dropdown-item" [href]="'http://www.tumblr.com/share/link?url='+ sharableUrl" target="_blank"><i class="fab fa-reddit mr-2"></i> {{ 'social.share.share-on' | translate }} Tumblr</a>-->
		<a class="dropdown-item" [href]="'http://pinterest.com/pin/create/button/?url='+ sharableUrl" target="_blank"><i class="fab fa-reddit mr-2"></i> {{ 'social.share.share-on' | translate }} Pinterest</a>
	</div>

</div>

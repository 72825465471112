import {Pipe, PipeTransform} from "@angular/core";
import * as Autolinker from "autolinker/dist/autolinker.min";

@Pipe({name: "parseLinks"})
export class ParseLinksPipe implements PipeTransform {
	transform(value: string, options?: any): string {
		return Autolinker.link(value, {
			replaceFn: function (match) {
				switch (match.type) {
					case "url":
						// spotify podcast
						if ((new URL(match.getUrl())).hostname === "open.spotify.com") {
							return `    `;
						}

						return true;  // let Autolinker perform its normal anchor tag replacement
				}
			}
		});
	}
}

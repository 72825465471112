import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Content} from "../../../shared/model/content/Content";
import {mainFeedAnimations} from "../../../commons/animations/mainFeedAnimations";
import {UserSessionService} from "../../../auth/session/services/user-session/user-session.service";

@Component({
	selector: "app-event-feed-item",
	templateUrl: "./event-feed-item.component.html",
	styleUrls: ["./event-feed-item.component.scss"],
	animations: [mainFeedAnimations],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventFeedItemComponent implements OnInit {

	@Input()
	public eventItem: Content;

	@Output()
	public imageLoaded: EventEmitter<void> = new EventEmitter<void>();

	constructor(private userSessionService: UserSessionService) {
	}

	ngOnInit() {
	}

	randomDildo() {
		return "../../../assets/images/sample-" + (Math.floor(Math.random() * 39)) + ".jpg";
	}

	public getLastImageIndex(): number {
		return this.eventItem.media.length - 1;
	}

	public preventNav() {
		this.userSessionService.preventNavigation();
	}

}

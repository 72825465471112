export enum CommentType {

	PRODUCT = "PRODUCT",
	POST = "POST",
	MICROBLOGPOST = "MICROBLOGPOST",
	EVENT = "EVENT",
	COMMENT = "COMMENT",
	REVIEW = "REVIEW"

}

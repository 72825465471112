import {Injectable} from "@angular/core";
import {ApiCommunicationService} from "../../shared/model/services/api-communication/api-communication.service";
import {ActivatedRouteSnapshot, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {Content} from "../../shared/model/content/Content";
import {map} from "rxjs/operators";

@Injectable()
export class EventResolverService  {

	constructor(private api: ApiCommunicationService) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Content> | Promise<Content> | Content {
		return this.api.event().getEvent(route.params["id"]).pipe(
			map(response => response)
		);
	}

}

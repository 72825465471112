import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from "@angular/core";
import {Location} from "@angular/common";
import {UserAvatarDisplayType} from "../../../commons/components/user-avatar/user-avatar.component";
import {PageTitleService} from "../../../commons/services/page-title/page-title.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ApiCommunicationService} from "../../../shared/model/services/api-communication/api-communication.service";
import {UserSessionService} from "../../../auth/session/services/user-session/user-session.service";
import {Content} from "../../../shared/model/content/Content";
import {PermissionLogicService} from "../../../shared/model/services/permission-logic/permission-logic.service";
import {EventService} from "../../services/event/event.service";
import {UpdateService} from "../../../commons/services/update/update.service";
import {SweetAlertService} from "../../../commons/services/sweetalert/sweet-alert.service";
import {TranslateService} from "@ngx-translate/core";

// declare var $: any;
// declare var CloudZoom: any;

@Component({
	selector: "app-event-page",
	templateUrl: "./event-page.component.html",
	styleUrls: ["./event-page.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventPageComponent implements OnInit, OnDestroy {

	// display options for components
	public _userAvatarDisplayType = UserAvatarDisplayType.IMAGE_ONLY;

	public event: Content;

	public isEditMode = false;

	public sub;

	constructor(private location: Location,
				private route: ActivatedRoute,
				private router: Router,
				private changeDetector: ChangeDetectorRef,
				private api: ApiCommunicationService,
				private pageTitleService: PageTitleService,
				private permissionService: PermissionLogicService,
				private eventService: EventService,
				private updateService: UpdateService,
				private alertService: SweetAlertService,
				private translateService: TranslateService,
				public userSessionService: UserSessionService) {
	}

	ngOnInit() {

		// get product from api
		this.route.data.subscribe((data) => {
			this.event = data.event;

			this.url();

			// reverse media array
			if (this.event && this.event.media && this.event.media.length) {
				this.event.media = this.event.media.reverse();
			}

			this.pageTitleService.setPageTitle(this.event.info.title);
			this.pageTitleService.setPageMeta({
				description: this.event.info.description,
				keywords: [this.event.info.genreOfEvent, this.event.info.location],
				author: this.event.user.displayName,
				image: this.event.media[0].url,
				type: "event",
			})
			this.isEditMode = this.permissionService.isPostEditable(this.event);
			this.changeDetector.detectChanges();
		});

		this.userSessionService.userData.subscribe((user) => {
			if (!user) {
				this.isEditMode = false;
			} else {
				this.isEditMode = this.permissionService.isPostEditable(this.event);
			}
		});

		this.sub = this.updateService.contentChangeEvent.subscribe((event: Content) => {
			this.event = event;

			this.url();

			if (this.event && this.event.media && this.event.media.length) {
				this.event.media = this.event.media.reverse();
			}

			this.pageTitleService.setPageTitle(
				`${this.event.info.title}, ${this.event.info.location}, ${this.event.info.startDate}`);
			this.pageTitleService.setPageMeta({
				description: this.event.info.description,
				keywords: [this.event.info.genreOfEvent, this.event.info.location],
				author: this.event.user.displayName,
				image: this.event.media[0].url,
				type: "event",
			})
			this.changeDetector.detectChanges();
		});

	}

	ngOnDestroy(): void {
		this.sub.unsubscribe();
	}

	isSingleImageGallery(): boolean {
		return this.event.media.length === 1;
	}

	backButtonAction() {
		this.location.back();
	}

	buyAction() {
	}

	editEventAction() {
		this.eventService.openEventEditModal(this.event);
	}

	url() {
		// avoiding brute force
		if (this.event.info.type === "INTERNAL") {
			if (this.event.info.link && !this.event.info.link.includes("http://") && !this.event.info.link.includes("https:://")) {
				this.event.info.link = "https://" + this.event.info.link;
			}

			if (this.event.info.linkToBookingSite && !this.event.info.linkToBookingSite.includes("http") &&
				!this.event.info.linkToBookingSite.includes("https:://")) {
				this.event.info.linkToBookingSite = "https://" + this.event.info.linkToBookingSite;
			}
		}
	}

	deleteEventAction() {
		this.alertService.confirm({title: this.translateService.instant("alert.delete")}).then((resp) => {
			if (resp.hasOwnProperty("value") && resp.value) {
				this.api.admin().deleteUgcContent(this.event._id).subscribe(() => {
					this.router.navigate(["/"]).then(() => {
						location.reload();
					});
				});
			}
		});

	}

	public isSameDay(date1, date2) {

		const d1 = new Date(date1);
		const d2 = new Date(date2);

		return d1.getFullYear() === d2.getFullYear() &&
			d1.getMonth() === d2.getMonth() &&
			d1.getDate() === d2.getDate();
	}
}

import {ChangeDetectionStrategy, Component, inject, Input, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {Content} from "../../../shared/model/content/Content";
import {ContentType} from "../../../shared/model/data/enums/ContentType";
import {Media} from "../../../shared/model/media/Media";
import {UserAvatarDisplayType} from "../../../commons/components/user-avatar/user-avatar.component";
import {PostBackgroundService} from "../../../commons/services/post-background/post-background.service";

@Component({
	selector: "app-conversations-item-small",
	templateUrl: "./conversations-item.component-small.html",
	styleUrls: ["./conversations-item.component-small.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConversationsItemSmallComponent implements OnInit {

	readonly route = inject(ActivatedRoute).root;

	@Input()
	data: Content;

	public mediaString: string;

	public displayType = UserAvatarDisplayType.NAME_NEXT_TO_IMAGE;

	protected POST = ContentType.POST;
	protected EVENT = ContentType.EVENT;
	protected MICROBLOGPOST = ContentType.MICROBLOGPOST;

	constructor(private router: Router,
				private postBackgroundService: PostBackgroundService) {
	}

	ngOnInit() {
		if (this.data.media && this.data.media.length >= 1) {
			this.data.media.forEach((media: Media) => {
				if (media.urlSmall && this.data.type === ContentType.POST) {
					this.mediaString = media.urlSmall;
					return;
				}

				// event
				if (this.data.type === ContentType.EVENT) {
					// internal event
					if (media.urlSmall) {
						this.mediaString = media.urlSmall;
						return;
					}

					// external event
					if (media.url) {
						this.mediaString = media.url;
						return;
					}
				}
			});
		}
	}

	public getBackground() {
		return this.postBackgroundService.getBackground(this.data.backgroundId);
	}

	public getTrimmedContent() {
		if (this.data.description.length > 50) {
			return this.data.description.substr(0, 50) + "...";
		}
		return this.data.description;
	}

	public gotoBlogPost() {
		this.router.navigate(["/blogs", this.data._id]);
	}

	public gotoPost() {
		// this.postService.openPostModal(this.data);
	}

	public gotoEvent() {
		this.router.navigate(["/event", this.data._id]);
	}

	public getPath() {
		switch (this.data.type) {
			case ContentType.POST:
				return "conversations";
			case ContentType.MICROBLOGPOST:
				return "conversations";
			case ContentType.EVENT:
				return "events";
		}
	}
}

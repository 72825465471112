import {ChangeDetectorRef, Component, Inject, Input, OnInit} from "@angular/core";
import {AbstractSocialButton} from "./abstract-social-button";
import {nullAnimation} from "../../animations/socialIconsStateChangeAnimation";
import {ApiCommunicationService} from "../../../shared/model/services/api-communication/api-communication.service";
import {UserSessionService} from "../../../auth/session/services/user-session/user-session.service";
import {ModalServiceProxy} from "../../../commons/services/modal/modal.service.proxy";
import {Content} from "../../../shared/model/content/Content";
import {Product} from "../../../shared/model/data/shop/product/Product";
import {ReportService} from "../../services/report/report.service";

@Component({
	selector: "app-social-button-report",
	templateUrl: "./abstract-social-button.html",
	styleUrls: ["./abstract-social-button.scss"],
	animations: [
		nullAnimation
	]
})
export class SocialButtonReportComponent extends AbstractSocialButton implements OnInit {

	@Input()
	public item: Content | Product;

	@Input()
	public type: "product" | "post" | "microblogpost" | "comment" | "event";

	constructor(@Inject(ApiCommunicationService) private api: ApiCommunicationService,
				@Inject(UserSessionService) private userSessionService: UserSessionService,
				@Inject(ModalServiceProxy) private modalServiceProxy: ModalServiceProxy,
				@Inject(ReportService) private reportService: ReportService,
				@Inject(ChangeDetectorRef) private changeDetector: ChangeDetectorRef) {
		super("report", "icon fi fi-rr-exclamation");
	}


	ngOnInit() {

		// hide the number around the icon
		this._displayCounter = false;

	}

	clickAction(): void {

		// check if user is present
		if (!this.userSessionService.userDataValue || !this.userSessionService.userDataValue._id) {
			this.noActiveUserAction();
			return;
		}

		this.reportService.openReportModal(this.item);
	}

	private noActiveUserAction(): void {
		this.modalServiceProxy.get().showLoginModal();
	}

}

import {ChangeDetectorRef, Component, Inject, OnInit} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ApiCommunicationService} from "../../../shared/model/services/api-communication/api-communication.service";
import {Comment} from "../../../shared/model/data/social/Comment";
import {AuthorizationError} from "../../../shared/model/exception/AuthorizationError";
import {ModalServiceProxy} from "../../../commons/services/modal/modal.service.proxy";
import {NavigationEnd, Router} from "@angular/router";
import {Product} from "../../../shared/model/data/shop/product/Product";
import {Content} from "../../../shared/model/content/Content";
import {CommentType} from "../../../shared/model/data/enums/CommentType";
import {UserSessionService} from "../../../auth/session/services/user-session/user-session.service";


@Component({
	selector: "app-comments-modal",
	templateUrl: "./comments-modal.component.html",
	styleUrls: ["./comments-modal.component.scss"]
})
export class CommentsModalComponent implements OnInit {

	public item: Content | Product;
	public title: string;
	public type: "product" | "post" | "microblogpost" | "event";

	constructor(private activeModal: NgbActiveModal,
				@Inject(ApiCommunicationService) private api: ApiCommunicationService,
				@Inject(ModalServiceProxy) private modalServiceProxy: ModalServiceProxy,
				@Inject(Router) private router: Router,
				@Inject(UserSessionService) private sessionService: UserSessionService,
				@Inject(ChangeDetectorRef) private changeDetector: ChangeDetectorRef) {

		this.router.events.subscribe( (event) => {
			if (event instanceof NavigationEnd) {
				this.close();
			}
		});
	}

	ngOnInit() {
	}

	close() {
		this.activeModal.close();
	}


	public sendComment(event: Event): void {

		// the source element of the event
		const inputElement: HTMLInputElement = (<HTMLInputElement>event.srcElement);

		// request to send
		const requestData = new Comment();
		requestData.message = inputElement.value;
		requestData.content = this.item._id;

		try {
			if (this.type === "microblogpost") {
				requestData.type = CommentType.MICROBLOGPOST;
				this.api.comment().comment(requestData)
					.subscribe(() => {
						this.handleCommentResponse(requestData);
					});
			} else if (this.type === "post") {
				requestData.type = CommentType.POST;
				this.api.comment().comment(requestData)
					.subscribe(() => {
						this.handleCommentResponse(requestData);
					});
			} else if (this.type === "event") {
				requestData.type = CommentType.EVENT;
				this.api.comment().comment(requestData)
					.subscribe(() => {
						this.handleCommentResponse(requestData);
					});
			} else {
				requestData.type = CommentType.PRODUCT;
				this.api.comment().comment(requestData)
					.subscribe(() => {
						this.handleCommentResponse(requestData);
					});
			}
		} catch (e) {
			// check if user is logged in, show login modal if not
			if (e instanceof AuthorizationError) {
				this.modalServiceProxy.get().showLoginModal();
			}
		}
	}

	private handleCommentResponse(commentFromServer: Comment) {
		// add comment from server to the comments
		commentFromServer.user = this.sessionService.userDataValue;
		this.item.comments.push(commentFromServer);

		// reset field
		(<HTMLInputElement>event.srcElement).value = "";

		// let the change detector know that changes happened
		this.changeDetector.markForCheck();
	}

}

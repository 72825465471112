import {Component, OnInit} from "@angular/core";
import {ApiCommunicationService} from "../../../shared/model/services/api-communication/api-communication.service";
import {UgcFeedData} from "../../../shared/model/data/social/UgcFeedData";
import {ContentListResponse} from "../../../shared/model/response/ContentListResponse";
import {forkJoin} from "rxjs";
import {ContentType} from "../../../shared/model/data/enums/ContentType";

@Component({
	selector: "app-social-feed",
	templateUrl: "./social-feed.component.html",
	styleUrls: ["./social-feed.component.scss"]
})
export class SocialFeedComponent implements OnInit {

	public ugcFeed: UgcFeedData = new UgcFeedData();

	public readonly POST = ContentType.POST;
	public readonly MICROBLOGPOST = ContentType.MICROBLOGPOST;
	public readonly EVENT = ContentType.EVENT;

	public selectedType: ContentType;
	public data: any;

	constructor(private api: ApiCommunicationService) {
	}

	ngOnInit() {
		forkJoin([
			this.api.post().getPosts(1),
			this.api.microblogpost().getMicroBlogPosts(1),
			this.api.event().getEvents(1),
		]).subscribe((data: Object[]) => {
			this.ugcFeed.posts = (<ContentListResponse>data[0]);
			this.ugcFeed.microblogposts = (<ContentListResponse>data[1]);
			this.ugcFeed.events = (<ContentListResponse>data[2]);
			// set default value
			this.select(this.POST);
		});
	}

	select(type: ContentType) {
		this.selectedType = type;
		switch (type) {
			case ContentType.POST:
				this.data = this.ugcFeed.posts;
				break;
			case ContentType.EVENT:
				this.data = this.ugcFeed.events;
				break;
			case ContentType.MICROBLOGPOST:
				this.data = this.ugcFeed.microblogposts;
				break;
		}
	}

}

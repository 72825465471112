<div class="modal-header">
	<h3>{{title}}</h3>
</div>
<div class="modal-body">
	<app-comments-display [item]="item"></app-comments-display>
</div>
<div class="modal-footer">
	<!--comments input-->
	<div class="item-comments-input">
		<div class="input-group">

			<!--input field-->
			<input (keydown.enter)="sendComment($event)"
				   id="comment-input"
				   type="text" class="form-control"
				   placeholder="{{ 'social.comments.input-placeholder' | translate }}"
				   required="required">

			<!--speech bubble icon-->
			<span class="input-group-addon"><i class="lovegivr lov-speech-bubble"></i></span>

		</div>
		<p class="send-hint">{{ "social.comments.send-with-enter-hint" | translate }}</p>
	</div>
</div>

<figure class="item" *ngIf="eventItem" [@fadeIn]>

	<div class="item-image-container" [routerLink]="['/event', eventItem._id]">
		<img [src]="(eventItem.media && eventItem.media.length)
						? (eventItem.media[getLastImageIndex()].url
							? eventItem.media[getLastImageIndex()].url
							: eventItem.media[getLastImageIndex()].urlMedium)
						: '../../../assets/images/image-placeholder.png'">

		<div class="social-buttons">
			<app-social-button-like (click)="preventNav()" id="prevent" [type]="'event'"
			                        [item]="eventItem"></app-social-button-like>
			<app-social-button-comment [item]="eventItem" [type]="'event'"></app-social-button-comment>
		</div>

		<div class="date-circle">
			<span class="month"><i class="fi fi-ss-calendar"></i> {{ eventItem.info.startDate | date: "MMM dd" }}
				| {{ eventItem.info.location }}</span>
		</div>

	</div>

	<figcaption>

		<div class="event-title">

			<!--item name-->
			<a class="item-name" [routerLink]="['/event', eventItem._id]">{{ eventItem.title }}</a>

			<!--seller-->
			<a class="item-publisher"
			   [routerLink]="['/user', eventItem.user.userName, eventItem.user._id]">
				<span class="highlight" [title]="eventItem.user.displayName">from {{ eventItem.user.userName }}</span>
			</a>
		</div>

	</figcaption>

</figure>

<div class="conversations-item" *ngIf="data">

	<app-user-avatar [displayType]="displayType" [navigationPath]="getPath()" [userObject]="data.user">
		<span subtitle>
			{{ data.updatedAt | date:'MMM d, yyyy' }}
		</span>
	</app-user-avatar>

	<!--event-->
	<div *ngIf="data.type === EVENT">

		<p (click)="gotoEvent()" class="cur-pointer">{{data.title}} on {{ data.info.startDate | date:'MMM d, yyyy' }}</p>

		<figure class="post-image cur-pointer"
			*ngIf="mediaString"
			(click)="gotoEvent()">
			<img [src]="mediaString">
		</figure>

		<div class="social-buttons">
			<app-social-button-like [item]="data" [title]="data.title" [type]="'event'"></app-social-button-like>
			<app-social-button-comment [item]="data" [title]="data.title" [type]="'event'"></app-social-button-comment>
		</div>
	</div>

	<!--blog post-->
	<div *ngIf="data.type === MICROBLOGPOST">

		<a class="flex flex-column" [routerLink]="['/blogs',data._id]">

			<p>{{data.title}}</p>

			<figure class="post-image cur-pointer" *ngIf="data.media && data.media[0] && data.media[0].urlSmall" (click)="gotoBlogPost()">
				<img [src]="data.media[0].urlSmall">
			</figure>
		</a>

		<div class="social-buttons">
			<app-social-button-like [item]="data" [title]="data.title" [type]="'microblogpost'"></app-social-button-like>
			<app-social-button-comment [item]="data" [title]="data.title" [type]="'microblogpost'"></app-social-button-comment>
		</div>
	</div>

	<!--post-->
	<div *ngIf="data.type === POST">

		<a class="flex flex-column" [routerLink]="[{outlets: {secondary: ['posts', data._id]}}]" [relativeTo]="route">
			<div class="text-only-post" *ngIf="data.backgroundId" >

				<div class="post-background" [innerHTML]="getBackground().backgroundHtml | keepHtml">
				</div>
				<div class="description">

					<p (click)="gotoPost()" class="cur-pointer">{{getTrimmedContent() | removeLinks}}</p>

				</div>
			</div>
			<div *ngIf="!data.backgroundId" >
				<p (click)="gotoPost()" class="cur-pointer">{{data.description | removeLinks}}</p>

				<figure class="post-image cur-pointer" *ngIf="mediaString">
					<img [src]="mediaString">
				</figure>
			</div>
		</a>

		<div class="social-buttons">
			<app-social-button-like [item]="data" [title]="data.title" [type]="'post'"></app-social-button-like>
			<app-social-button-comment [item]="data" [title]="data.title" [type]="'post'"></app-social-button-comment>
		</div>
	</div>


</div>

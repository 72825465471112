import {Injectable} from "@angular/core";
import {CommentsModalComponent} from "../../modals/comments-modal/comments-modal.component";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {Product} from "../../../shared/model/data/shop/product/Product";
import {Content} from "../../../shared/model/content/Content";

@Injectable()
export class CommentsService {

	private _activeModal: NgbModalRef;

	constructor(private modalService: NgbModal) {
	}

	showComments(item: Content | Product, title: string, type: "product" | "post" | "microblogpost" | "event"): void {
		this._activeModal = this.modalService.open(CommentsModalComponent);
		(<CommentsModalComponent>this._activeModal.componentInstance).item = item;
		(<CommentsModalComponent>this._activeModal.componentInstance).title = title;
		(<CommentsModalComponent>this._activeModal.componentInstance).type = type;
	}


	get activeModal(): NgbModalRef {
		return this._activeModal;
	}
}

<article id="product-details" *ngIf="event">

	<div id="product-details-images">

		<div id="meta-info">

			<!--like, share, add buttons-->
			<app-social-buttons [item]="event" [type]="'event'"></app-social-buttons>

			<!--user avatar-->
			<app-user-avatar [navigationPath]="'events'" [displayType]="_userAvatarDisplayType" [userObject]="event.user"></app-user-avatar>

		</div>
		<ngb-carousel *ngIf="!event.media.length; else media" [interval]="0" [showNavigationArrows]="false" [ngClass]="{'single-image-gallery': isSingleImageGallery()}">
			<ng-template ngbSlide>
				<img  class="main-image" src="../../../assets/images/image-placeholder.png">
			</ng-template>
		</ngb-carousel>

		<ng-template #media class="main-image">
			<ngb-carousel [interval]="0" [showNavigationArrows]="event.media.length > 1">
				<!--images-->
				<ng-template ngbSlide *ngFor="let image of event.media">
					<img *ngIf="image.urlLarge; else eventbrite" [src]="image.urlLarge">
					<ng-template #eventbrite>
						<img [src]="image.url">
					</ng-template>
				</ng-template>
			</ngb-carousel>
		</ng-template>

	</div>

	<!--product details panel-->
	<div id="product-details-main">

		<summary>

			<div>

				<!--edit button-->
				<button class="btn btn-outline-primary float-right"
						*ngIf="isEditMode"
						(click)="editEventAction()">
					<i class="fi fi-ss-pencil"></i>
				</button>

				<button class="btn btn-outline-primary float-right"
						*ngIf="userSessionService.userDataValue && userSessionService.userDataValue.role === 'ADMIN'"
						(click)="deleteEventAction()">
					<i class="fi fi-ss-trash"></i>
				</button>
			</div>

			<!--name of the product-->
			<h1 class="item-title">{{event.title}}</h1>

			<p class="item-date" *ngIf="!isSameDay(event.info.startDate, event.info.endDate) else multipledays">
				<span class="date">{{event.info.startDate | dateOrdinal }}&nbsp;</span>
				<span class="text-lowercase">{{ event.info.startDate | date:'h:mm a' }}</span>
				-<br>
				<span class="date">{{event.info.endDate | dateOrdinal }}&nbsp;</span>
				<span class="text-lowercase">{{ event.info.endDate | date:'h:mm a' }}</span>
			</p>

			<ng-template #multipledays>
				<p class="font-weight-bold item-date">
					<span class="date">{{event.info.endDate | dateOrdinal }}&nbsp;</span>
					<span class="text-lowercase">{{event.info.startDate | date:'h:mm a'}} - {{event.info.endDate | date:'h:mm a'}}</span>
				</p>
			</ng-template>

			<!--location and genre-->
			<div class="spec">
				<p class="genre"><span><b>{{event.info.genreOfEvent | uppercase}}</b> EVENT</span></p>
				<p class="location"><i class="fi fi-ss-marker"></i> {{event.info.location | uppercase}}</p>
			</div>

			<!--price-->
			<div>
				<h1 class="item-price my-2">
					<ng-container *ngIf="event.info.priceMin > 0; else free">
						<span>PRICE:&nbsp;</span>
						<span class="black" >{{event.info.priceMin | currency: event.info.currency}}</span>
						<span class="black" *ngIf="+event.info.priceMax > +event.info.priceMin"> - {{event.info.priceMax | currency: event.info.currency}}</span>
					</ng-container>
				</h1>
			</div>

			<ng-template #free>
				<h1 class="item-price my-2">FREE</h1>
			</ng-template>


			<!--item description-->
			<p class="item-description my-4 pad-top">
				<span #itemDescription id="read-more" class="item-description" [innerHTML]="event.description">
				</span>

				<!--read more button-->
				<!--<a class="read-more" readMore [readMore-length]="350" [readMore-element]="itemDescription">-->
					<!--<a href="javascript:void(0);" class="less cur-pointer">{{ "shop.read-more.show-less" | translate }}</a>-->
					<!--<a href="javascript:void(0);" class="more cur-pointer">{{ "shop.read-more.show-more" | translate }}</a>-->
				<!--</a>-->
			</p>

			<!--main form (buy/select)-->
			<div id="item-shop-form">


				<form (submit)="buyAction()" id="item-buy-form" class="my-4">

					<div class="row">
						<!--<div class="col-9 pr-0">-->

							<!--&lt;!&ndash;variant selector&ndash;&gt;-->
							<!--<select required class="form-control" [(ngModel)]="selectedVariant" name="variant-selector" (change)="selectVariantAction()">-->
								<!--<option selected disabled *ngIf="product.variants.length > 1" [value]="null">VARIANT</option>-->
								<!--<option-->
									<!--*ngFor="let variant of product.variants; let i = index"-->
									<!--[value]="i">{{variant.sku}}</option>-->
							<!--</select>-->

						<!--</div>-->

						<!--&lt;!&ndash;quantity picker&ndash;&gt;-->
						<!--<div class="col-3 pl-0">-->
							<!--<input type="number" name="quantity" required min="1" [(ngModel)]="quantity" class="form-control" placeholder="{{ 'shop.item-shop-form.quantity-placeholder' | translate }}" [disabled]="!isVariantAvailable">-->
						<!--</div>-->

						<!--&lt;!&ndash;buy now&ndash;&gt;-->
						<div class="col-12">
							<a class="item-buy-now btn btn-primary text-uppercase" target="_blank" [href]="event.info.link ? event.info.link : event.info.linkToBookingSite">BOOK NOW</a>
						</div>
					</div>

				</form>
			</div>

		</summary>

		<!--comments section-->
		<section id="item-comments">

			<p class="nui-label">{{ 'social.comments.title' | translate }}</p>

			<!--comments part-->
			<app-event-comments [item]="event"></app-event-comments>

		</section>

	</div>

</article>

<!--back button-->
<a id="page-back-button" (click)="backButtonAction()" placement="right" container="body" ngbTooltip="Back">
	<svg xmlns="http://www.w3.org/2000/svg" id="Isolation_Mode" data-name="Isolation Mode" viewBox="0 0 24 24"
		 width="512" height="512">
		<path
			d="M16.041,24,6.534,14.48a3.507,3.507,0,0,1,0-4.948L16.052,0,18.17,2.121,8.652,11.652a.5.5,0,0,0,0,.707l9.506,9.52Z"/>
	</svg>
</a>

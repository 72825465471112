import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {NgbModal, NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {ConversationsComponent} from "./components/conversations/conversations.component";
import {SocialFeedComponent} from "./components/social-feed/social-feed.component";
import {SocialButtonLikeComponent} from "./components/social-button/social-button-like.component";
import {SocialButtonsComponent} from "./components/social-buttons/social-buttons.component";
import {
	SocialButtonAddToCollectionComponent
} from "./components/social-button/social-button-add-to-collection.component";
import {SocialButtonShareComponent} from "./components/social-button/social-button-share.component";
import {SocialButtonCommentComponent} from "./components/social-button/social-button-comment.component";
import {
	ConversationsItemSmallComponent
} from "./components/conversations-item-small/conversations-item.component-small";
import {CommentsModalComponent} from "./modals/comments-modal/comments-modal.component";
import {CommentsDisplayComponent} from "./components/comments-display/comments-display.component";
import {EventFeedItemComponent} from "./components/event-feed-item/event-feed-item.component";
import {ConversationsFeedItemComponent} from "./components/conversations-feed-item/conversations-feed-item.component";
import {CollectionService} from "./services/collection/collection.service";
import {NgxEditorModule} from "ngx-editor";
import {FormsModule} from "@angular/forms";
import {RouterModule, Routes} from "@angular/router";
import {BlogPostCommentsComponent} from "./components/blogpost-comments/blogpost-comments.component";

import {EventPageComponent} from "./pages/event-page/event-page.component";
import {EventResolverService} from "./resolvers/event-resolver.service";
import {EventCommentsComponent} from "./components/event-comments/eventCommentsComponent";
import {SocialButtonReportComponent} from "./components/social-button/social-button-report.component";
import {InfiniteScrollDirective} from "ngx-infinite-scroll";
import {CreateEventModalComponent} from "./modals/create-event-modal/create-event-modal.component";
import {EventService} from "./services/event/event.service";
import {UgcReFetchService} from "./services/ugc-feed/ugc-re-fetch.service";
import {LikeService} from "./services/like/like.service";
import {EditEventModalComponent} from "./modals/edit-event-modal/edit-event-modal.component";
import {ReportModalComponent} from "./modals/report-modal/report-modal.component";
// import {AngularDateTimePickerModule} from "angular2-datetimepicker";
import {ModelModule} from "../shared/model/model.module";
import {CommonsPipesModule} from "../commons/commons.pipes.module";
import {CommonsModule} from "../commons/commons.module";

const routes: Routes = [
	{
		path: "event/:id",
		component: EventPageComponent,
		resolve: {
			event: EventResolverService
		}
	}
];

/**
 * Social module.
 *
 * Responsible for all social interactions including (but not limited to):
 * - Commenting
 * - Liking
 * - Conversations panel
 * - External social share
 */
@NgModule({
	imports: [
		CommonModule,
		CommonsModule,
		CommonsPipesModule,
		TranslateModule.forChild(),
		NgbModule,
		FormsModule,
		RouterModule.forChild(routes),
		// ngx-editor
		NgxEditorModule,
		ModelModule,

		// AngularDateTimePickerModule,

		// scroll paging
		InfiniteScrollDirective,
	],
	declarations: [
		SocialFeedComponent,
		SocialButtonLikeComponent,
		SocialButtonsComponent,
		SocialButtonAddToCollectionComponent,
		SocialButtonShareComponent,
		SocialButtonCommentComponent,
		SocialButtonReportComponent,
		CommentsModalComponent,
		CommentsDisplayComponent,
		EventFeedItemComponent,
		ConversationsComponent,
		ConversationsItemSmallComponent,
		ConversationsFeedItemComponent,
		// CreateBlogpostModalComponent,
		// BlogPostPageComponent,
		BlogPostCommentsComponent,
		EventPageComponent,
		EventCommentsComponent,
		// EditBlogpostModalComponent,
		CreateEventModalComponent,
		EditEventModalComponent,
		ReportModalComponent,
		// EscapeHtmlPipe,
	],
	exports: [
		ConversationsComponent,
		SocialFeedComponent,
		SocialButtonsComponent,
		CommentsModalComponent,
		CommentsDisplayComponent,
		EventFeedItemComponent,
		ConversationsFeedItemComponent,
		// EditBlogpostModalComponent,
		CreateEventModalComponent,
		EditEventModalComponent,
		SocialButtonShareComponent,
		SocialButtonLikeComponent,
		SocialButtonCommentComponent
	],
	providers: [
		NgbModal,
		CollectionService,
		EventResolverService,
		EventService,
		UgcReFetchService,
		LikeService
	]
})
export class SocialModule {
}

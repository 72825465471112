import {Component, Input, OnInit} from "@angular/core";
import {UserSessionService} from "../../../auth/session/services/user-session/user-session.service";
import {Content} from "../../../shared/model/content/Content";
import {Product} from "../../../shared/model/data/shop/product/Product";
import {Role} from "../../../shared/model/data/enums/Role";

@Component({
	selector: "app-social-buttons",
	templateUrl: "./social-buttons.component.html",
	styleUrls: ["./social-buttons.component.scss"]
})
export class SocialButtonsComponent implements OnInit {

	@Input()
	public item: Content | Product;

	@Input()
	public type: "product" | "post" | "microblogpost" | "event";

	@Input()
	public orientation: "vertical" | "horizontal" = "vertical";

	@Input()
	public showLikeButton = true;

	// display rules of buttons
	@Input()
	public showAddToCollectionButton = false;

	@Input()
	public showCommentButton = false;

	@Input()
	public showReportContentButton = true;

	@Input()
	public showShareButton = true;

	constructor(public userSessionService: UserSessionService) {
	}

	ngOnInit() {
		this.showCommentButton = (this.type !== "product" && this.type !== "microblogpost" && this.type !== "post" && this.type !== "event");
		this.showAddToCollectionButton = this.type === "product";

		this.userSessionService.userData.subscribe((user) => {
			if (user && user.role === Role.MERCHANT && this.type === "product") {
				if (this.item.user.userName !== user.userName) {
					this.showAddToCollectionButton = false;
				}
			}
		});

	}

}

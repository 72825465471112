<div id="feed"
	infiniteScroll
	*ngIf="data && data.docs.length"
	[infiniteScrollDistance]="1"
	[infiniteScrollThrottle]="300"
	[infiniteScrollContainer]="selector"
	[fromRoot]="true"
	(scrolled)="onScrollDown()">
	<app-conversations-item-small *ngFor="let item of data.docs" [data]="item"></app-conversations-item-small>
</div>

import {Component, Inject, Input, OnInit} from "@angular/core";
import {CommentsService} from "../../services/comments/comments.service";
import {AbstractSocialButton} from "./abstract-social-button";
import {nullAnimation} from "../../animations/socialIconsStateChangeAnimation";
import {Router} from "@angular/router";
import {Content} from "../../../shared/model/content/Content";
import {UserSessionService} from "../../../auth/session/services/user-session/user-session.service";
import {ModalServiceProxy} from "../../../commons/services/modal/modal.service.proxy";

@Component({
	selector: "app-social-button-comment",
	templateUrl: "./abstract-social-button.html",
	styleUrls: ["./abstract-social-button.scss"],
	providers: [
		CommentsService
	],
	animations: [
		nullAnimation
	]
})
export class SocialButtonCommentComponent extends AbstractSocialButton implements OnInit {

	@Input()
	public item: Content;

	@Input()
	public title: string;

	@Input()
	public type: "product" | "post" | "microblogpost" | "event" = "product";

	constructor(private commentsService: CommentsService,
				@Inject(UserSessionService) private userSessionService: UserSessionService,
				@Inject(ModalServiceProxy) private modalServiceProxy: ModalServiceProxy,
				private router: Router) {
		super("comment", "lovegivr icon lov-speech-bubble");
	}

	ngOnInit() {

		// init number to display
		if (this.item) {
			this._displayedNumber = this.item.numberOfComments;
		}
	}

	clickAction(): void {
		if (this.item) {
			// check if user is present
			if (!this.userSessionService.userDataValue || !this.userSessionService.userDataValue._id) {
				this.noActiveUserAction();
				return;
			}

			if (this.type === "post") {
				// this.postService.openPostModal(this.item);
			}

			if (this.type === "microblogpost") {
				this.router.navigate(["blogpost", this.item._id]);
			}
		}
	}

	private noActiveUserAction(): void {
		this.modalServiceProxy.get().showLoginModal();
	}

	get displayedNumber(): number {
		return this.item.numberOfComments;
	}
}

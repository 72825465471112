<figure class="item" *ngIf="data"
        (click)="navigate()">
	<a class="item-container">
		<div class="social-buttons">
			<app-social-button-like (click)="preventNav()" id="prevent" [item]="data"
			                        [type]="type"></app-social-button-like>
			<app-social-button-comment [item]="data" [type]="type"></app-social-button-comment>
		</div>

		<div class="text-only-post" *ngIf="data.backgroundId">

			<div class="post-background" [innerHTML]="getBackground().backgroundHtml | keepHtml">
			</div>
			<div class="description">
				{{ (data.title || getTrimmedContent()) | removeLinks }}
			</div>
		</div>


		<div class="text-only-post no-fancy-background" *ngIf="!data.backgroundId && !data.media[getLastImageIndex()]">

			<div class="post-background">
			</div>
			<div class="description">
				{{ (data.title || getTrimmedContent()) | removeLinks }}
			</div>

			<!--blog badge-->
			<div class="blog-badge" *ngIf="showBadge && type === 'microblogpost'">Blog post</div>
		</div>

		<div *ngIf="!data.backgroundId && data.media.length" class="item-image-container">
			<img [src]="data.media && data.media.length && data.media[getLastImageIndex()].urlMedium
						? data.media[getLastImageIndex()].urlMedium : '../../../assets/images/image-placeholder.png'">
			<!--blog badge-->
			<div class="blog-badge" *ngIf="showBadge && type === 'microblogpost'">Blog post</div>
		</div>

	</a>

	<figcaption>
		<div class="post-content">
			<a class="post-text"
			   *ngIf="!data.backgroundId && !!data.media[getLastImageIndex()] && (data.title || data.description)">
				<span *ngIf="data.title">{{ data.title }}&nbsp;</span>
				<span *ngIf="!data.title">{{ getTrimmedContent() | removeLinks }}</span>
			</a>
			<!--seller-->
			<a class="post-publisher"
			   [routerLink]="['/user', data.user.userName, data.user._id]"
			   *ngIf="showAuthorName">
				<span class="highlight" [title]="data.user.userName">{{ data.user.displayName }}</span>
			</a>
		</div>

	</figcaption>

</figure>

<div id="social-feed" *ngIf="ugcFeed">

	<div class="social-feed-title">{{ 'social.feed.title' | translate }}</div>

	<div class="social-tabs">
		<div class="tab" [ngClass]="{'active': selectedType === POST}" (click)="select(POST)">{{ 'social.feed.posts' | translate }}</div>
		<div class="tab" [ngClass]="{'active': selectedType === MICROBLOGPOST}" (click)="select(MICROBLOGPOST)">{{ 'social.feed.stories' | translate }}</div>
		<div class="tab" [ngClass]="{'active': selectedType === EVENT}" (click)="select(EVENT)">{{ 'social.feed.events' | translate}}</div>
	</div>

	<app-conversations [data]="data" [type]="selectedType"></app-conversations>


</div>

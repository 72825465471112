<div class="modal-header">
	<h3>Report</h3>

	<button type="button" class="close nui-button secondary round" aria-label="Close" (click)="modalRef.dismiss('Cross click')">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<div class="modal-body">

	<div class="form-group">
		<label>Message</label>
		<textarea class="form-control-plaintext"
				[(ngModel)]="requestBody.message"
				maxlength="10000"
				placeholder="Enter text here..."></textarea>
	</div>

</div>

<div class="modal-footer justify-content-between">

	<svg *ngIf="isLoading" class="loader" width="52" height="52" viewBox="0 0 52 52">
		<circle cx="26" cy="26" r="24" fill="none" stroke="#FF2C63" stroke-width="4"></circle>
	</svg>

	<!--submit button-->
	<button class="btn btn-primary" (click)="reportAction()"
			[disabled]="isLoading || (!requestBody.message || requestBody.message.length < 3)">Send report</button>

</div>


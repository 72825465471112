<div ngbDropdown>

	<a class="social-button {{'social-button-' + buttonClass}}"
	   [ngClass]="{'active' : iconState}"
	   (click)="clickAction()"
	   href="javascript:void(0)"
	   ngbDropdownToggle
	   [@iconAnimation]="iconState ? 'active' : 'inactive'">
		<span class="number">{{displayedNumber}}</span>
		<i class="lovegivr icon" [ngClass]="iconClass"></i>
	</a>

	<!--dropdown menu-->
	<div ngbDropdownMenu>
		<!--menu header-->
		<h6 class="dropdown-header">{{ 'social.collections.add-to-collection-menu-header' | translate }}</h6>
		<!--my collections-->
		<button class="dropdown-item"
		        (click)="addToCollection(collection._id)"
		        *ngFor="let collection of collectionService.getCollections() | async">{{collection.name}}</button>

		<div class="dropdown-divider"></div>

		<!--add new collection-->
		<button class="dropdown-item" (click)="addToNewCollection()">{{ 'social.collections.create-new-collection' | translate }}</button>
	</div>

</div>

import {Injectable} from "@angular/core";
import {UserSessionService} from "../../../../auth/session/services/user-session/user-session.service";
import {User} from "../../data/user/User";
import {Content} from "../../content/Content";

@Injectable()
export class PermissionLogicService {

	public placeholder = null;

	constructor(private userSessionService: UserSessionService) {
	}

	public isPostEditable(post: Content): boolean {
		return this.isLoggedInRule() && (this.isOwnerRule(post.user));
	}

	private isLoggedInRule(): boolean {
		return !!this.userSessionService.userDataValue;
	}

	private isOwnerRule(user: User): boolean {
		return user && user.userName === this.userSessionService.userDataValue.userName;
	}

	// TODO RR
	// private isAdminRule(): boolean {
	// 	return this.userSessionService.userDataValue.roles.some(role => role === Role.ADMIN);
	// }

}
